import styled from "styled-components";
import { FaLinkedin, FaTwitterSquare, FaYoutubeSquare } from "react-icons/fa";
import logo from "../static/images/storlytics_white.png"
import CookieConsent from "react-cookie-consent";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Div className="d-flex justify-content-between p-4">
      <div className="d-flex flex-column align-items-start" style={{ gap: 8 }}>
        <img src={logo} height={48} />
        <span>&copy; {currentYear} Storlytics</span>
      </div>

      <div style={{ display: "flex", alignItems: "end", gap: 8 }}>
        <a
          href="https://www.linkedin.com/company/storlytics/"
          style={{ width: 32, height: 32, display: "inline-block" }}
        >
          <FaLinkedin color="white" style={{ width: 24, height: 24 }} />
        </a>
        <a
          href="https://twitter.com/storlytics/"
          style={{ width: 32, height: 32, display: "inline-block" }}
        >
          <FaTwitterSquare color="white" style={{ width: 24, height: 24 }} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCgyxkGuz2X_jIximCXtk15w"
          style={{ width: 32, height: 32, display: "inline-block" }}
        >
          <FaYoutubeSquare color="white" style={{ width: 24, height: 24 }} />
        </a>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="StorlyticsCookie2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}>
        <ul>  
        {" "} This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. {" "}
        <span style={{ fontSize: "13px" }}> {" "} <br></br> We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our <a href="/PrivacyPolicy">Privacy Policy</a>. </span>
        </ul>
      </CookieConsent>
    </Div>
  );
};

export default Footer;

const Div = styled.div`
  margin: 48px 0 0;
  background: #04ac4e;

  span {
    color: white;
  }
`;
