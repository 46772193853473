import loadable from "@loadable/component";
import KnowledgeBase from "../pages/KnowledgeBase";
import EditKnowledgeBase from "../pages/EditKnowledgeBase";
import QuoteForm from "../pages/QuoteForm";
// import Home from "../pages/Home";
// import Download from "../pages/Download";
// import ManageShop from "../pages/ManageShop";
// import Shop from "../pages/Shop";
// import Registration from "../pages/Registration";
// import CustomerData from "../pages/CustomerData";
// import ChangePassword from "../pages/ChangePassword";
// import Order from "../pages/Order";
// import Payment from "../pages/Payment";
// import Quote from "../pages/Quote";
// import AdminDashboard from "../pages/AdminDashboard";
// import ContactUs from "../pages/ContactUs";
const IndexPage = loadable(() => import("../pages/IndexPage"));
const Home = loadable(() => import("../pages/Home"));
const Download = loadable(() => import("../pages/Download"));
const ManageShop = loadable(() => import("../pages/ManageShop"));
const RefundPolicy = loadable(() => import("../pages/RefundPolicy"));

const EnergyStorageSoftware = loadable(() =>
  import("../pages/EnergyStorageSoftware")
);
const Registration = loadable(() => import("../pages/Registration"));
const FreeAcademicLicense = loadable(() =>
  import("../pages/FreeAcademicLicense")
);
const CustomerData = loadable(() => import("../pages/CustomerData"));
const ChangePassword = loadable(() => import("../pages/ChangePassword"));
const Order = loadable(() => import("../pages/Order"));
const Payment = loadable(() => import("../pages/Payment"));
const Quote = loadable(() => import("../pages/Quote"));
const AdminDashboard = loadable(() => import("../pages/AdminDashboard"));
const ContactUs = loadable(() => import("../pages/ContactUs"));
const AboutUs = loadable(() => import("../pages/AboutUs"));
const PrivacyPolicy = loadable(() => import("../pages/PrivacyPolicy"));
const ES = loadable(() => import("../pages/ES"));
const KBArticle = loadable(() => import("../pages/KBArticle"));
const PDFContentViewer = loadable(() => import("../pages/PdfContent"));

const routes = [
  {
    path: "/RefundPolicy",
    component: RefundPolicy,
    exact: true,
    is_auth: false,
    name: "RefundPolicy",
  },
  {
    path: "/",
    component: IndexPage,
    exact: true,
    is_auth: false,
    name: "index",
  },
  {
    path: "/energy_storage_software",
    component: EnergyStorageSoftware,
    exact: true,
    is_auth: false,
    name: "energy_storage_software",
  },
  {
    path: "/download",
    component: Download,
    exact: true,
    is_auth: false,
    name: "download",
  },
  {
    path: "/energy_storage_consulting",
    component: ES,
    exact: true,
    is_auth: false,
    name: "energy_storage_consulting",
  },
  {
    path: "/knowledge_base",
    component: KnowledgeBase,
    exact: true,
    is_auth: false,
    name: "knowledge_base",
  },
  {
    path: "/knowledge_base/:id",
    component: KBArticle,
    exact: true,
    is_auth: false,
    name: "knowledge_base/:id",
  },
  {
    path: "/knowledge-base/edit/:id",
    component: EditKnowledgeBase,
    exact: true,
    is_auth: false,
    name: "knowledge-base/edit/:id",
  },
  {
    path: "/contact_us",
    component: ContactUs,
    exact: true,
    is_auth: false,
    name: "contact_us",
  },
  {
    path: "/request-free-academic-license",
    component: FreeAcademicLicense,
    exact: true,
    is_auth: false,
    name: "request-free-academic-license",
  },
  {
    path: "/quote-for-consulting",
    component: QuoteForm,
    exact: true,
    is_auth: false,
    name: "quote-for-consulting",
  },
  {
    path: "/about_us",
    component: AboutUs,
    exact: true,
    is_auth: false,
    name: "about_us",
  },
  {
    path: "/register",
    component: Registration,
    exact: true,
    is_auth: false,
    name: "register",
  },
  {
    path: "/shop/login",
    component: ManageShop,
    exact: true,
    is_auth: false,
    name: "login",
  },
  {
    path: "/home",
    component: Home,
    exact: true,
    is_auth: true,
    name: "home",
  },
  {
    path: "/home/customer-data",
    component: CustomerData,
    is_auth: true,
    exact: true,
    name: "customer_data",
  },
  {
    path: "/home/modify_profile/:user_id",
    component: Registration,
    is_auth: true,
    exact: true,
    name: "modify_profile",
  },
  {
    path: "/home/order",
    component: Order,
    is_auth: true,
    exact: true,
    name: "order",
  },
  {
    path: "/home/payment",
    component: Payment,
    is_auth: true,
    exact: true,
    name: "payment",
  },
  {
    path: "/home/quote",
    component: Quote,
    is_auth: true,
    exact: true,
    name: "quote",
  },
  {
    path: "/home/change_password",
    component: ChangePassword,
    is_auth: true,
    exact: true,
    name: "change_password",
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    is_auth: true,
    exact: true,
    name: "admin_dashboard",
  },
  {
    path: "/PrivacyPolicy",
    component: PrivacyPolicy,
    exact: true,
    is_auth: false,
    name: "PrivacyPolicy",
  },
  {
    path: "/activation-guide",
    component: (props) => <PDFContentViewer {...props} pageName="download" />,
    is_auth: false,
    pageName: "download",
    name: "activation-guide",
  },
];

export default routes;
